<div class="h-screen flex flex-row justify-around items-center content-center gap-4	md:mx-4">
  <div class=" w-11/12 md:w-1/2 xl:w-2/5  h-auto  bg-aguacate-bg rounded-lg shadow  xs:p-0">
    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
      <div class="container flex flex-col items-center">
        <img class="w-64" src="assets/img/aguacate-logo.png" alt="Aguacate cambios, profesionales en remesa" />
      </div>
      <form class="space-y-4 md:space-y-6" [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm)">
        <div>
          <label for="email" class="block mb-2 text-sm font-medium text-primary">Correo electrónico</label>
          <input type="email" name="email" id="email"  formControlName="email" (blur)="checkUserEmail()" autocomplete="on"bub
            class="bg-aguacate-green-50 border border-aguacate-green-300 text-primary-900 sm:text-sm rounded-lg focus:ring-aguacate-green-600 focus:border-aguacate-green-600 block w-full p-2.5"
            placeholder="name@company.com" required="true" />
            <div *ngIf="loginForm.get('email')?.invalid && (loginForm.get('email')?.dirty || loginForm.get('email')?.touched)">
              <span *ngIf="loginForm.get('email')?.hasError('required')" class="text-sm text-red-600"> Ingresa el email </span>
            </div>
            <div >
              <span  class="text-sm text-red-600" *ngIf="unregisteredMail">Este correo no tiene vinculada alguna cuenta con nosotros, te invitamos a <a class="underline" [routerLink]="['/auth/signup']">registrarte</a  > </span>
            </div>
        </div>
        <div>
          <label for="password" class="block mb-2 text-sm font-medium text-gray-900" >Contraseña</label>
          <input type="password" name="password" id="password" placeholder="••••••••" formControlName="password" autocomplete="on"
            class="bg-aguacate-green-50 border border-aguacate-green-300 text-primary-900 sm:text-sm rounded-lg focus:ring-aguacate-green-600 focus:border-aguacate-green-600 block w-full p-2.5"
            required="" />
            <div *ngIf="loginForm.get('password')?.invalid && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">
              <span *ngIf="loginForm.get('password')?.hasError('required')" class="text-sm text-red-600"> Ingresa la contraseña </span>
            </div>

        </div>
        <div class="container flex flex-col items-center">
          <button type="submit" [disabled]="loginForm.invalid"
            class="w-1/2 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Iniciar sesión
          </button>
          <span *ngIf="loginError && !unregisteredMail" class="text-sm text-red-600 mt-4"> Credenciales incorrectas </span>
          <p class="p-4 text-sm font-light text-primary-500 text-center">
            Al continuar, estás aceptando
            <a href="https://aguacate.s3-sa-east-1.amazonaws.com/POLITICAS+DE+PRIVACIDAD.pdf" rel="noreferrer"
              target="_blank" class="font-medium text-primary-600 hover:underline">
              nuestros Términos y Condiciones</a>
          </p>
        </div>
      </form>
      <div class="w-full container flex flex-row justify-around ">
        <button class="font-medium text-center text-primary-600 hover:underline" [routerLink]="['/auth/retail-password-reset']">
          ¿Olvidaste tu contraseña?
        </button>
        <button class="font-medium text-center text-primary-600 hover:underline" [routerLink]="['/auth/retail-signup']">
         ¿ No tienes una cuenta ? Registrate
        </button>
      </div>
    </div>
  </div>
</div>
<!-- <div class="row justify-content-center my-5">
  <div class="col-4">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col mb-2">
            <label for="email" class="form-label">Email:</label>
            <input type="email" id="email" name="email" #email="ngModel" [(ngModel)]="user.email"
              class="form-control form-control-sm" />
          </div>
        </div>
        <div class="row">
          <div class="col mb-2">
            <label for="password" class="form-label">Password:</label>
            <div class="input-group input-group-sm">
              <input [type]="user.showPassword ? 'text' : 'password'" id="password" name="password" #password="ngModel"
                [(ngModel)]="user.password" class="form-control form-control-sm" />
              <button type="button" class="btn btn-outline-secondary" (click)="user.showPassword = !user.showPassword">
                <i class="bi" [ngClass]="{
                    'bi-eye-fill': !user.showPassword,
                    'bi-eye-slash-fill': user.showPassword
                  }"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col d-grid">
            <button type="button" (click)="signIn()" class="btn btn-sm btn-success" [disabled]="loading">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
              Sign in
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
