import * as moment from "moment-timezone";
import { CurrencyPipe } from '@angular/common';

export function getColumns(currencyPipe: CurrencyPipe) {
  return [
    {
      columnDef: 'id',
      header: 'ID',
      cell: (element: any) => `${element.id}`,
    },
    {
      columnDef: 'createdAt',
      header: 'Fecha creación',
      cell: (element: any) => `${element.createdAt ? moment(new Date(element.createdAt), 'America/Santiago').format('lll'): ''}`,
    },
    {
      columnDef: 'canal',
      header: 'Canal',
      cell: (element: any) => `${element.tipoTransaccion === 'AC' ? 'Detal (AG Cambios)' : 'Mayorista (AG Wallet)'}`,
    },
    {
      columnDef: 'fechaTermino',
      header: 'Fecha Término',
      cell: (element: any) => `${element.fechaTermino ?  moment(new Date(element.fechaTermino), 'America/Santiago').format('lll')  : 'No aplica' }`,
    },
    {
      columnDef: 'fechaFondeoWallet',
      header: 'Fecha fondeo wallet',
      cell: (element: any) => `${element.fechaFondeoWallet  ?  moment(new Date(element.fechaFondeoWallet), 'America/Santiago').format('lll') : 'No aplica'}`,
    },

    {
      columnDef: 'userId',
      header: 'Nombre usuario',
      cell: (element: any) => `${element.usuario.nombre} ${element.usuario.apellidos}`,
    },
    {
      columnDef: 'cuentaBeneficiarioId',
      header: 'Beneficiario',
      cell: (element: any) => `${element.cuentaBeneficiario.nombre} ${element.cuentaBeneficiario.apellidos}`,
    },
    {
      columnDef: 'ruta',
      header: 'Ruta',
      cell: (element: any) => `${element.paisOrigen} - ${element.paisDestino}`,
    },
    {
      columnDef: 'totalMonedaOrigen',
      header: 'Monto origen',
      cell: (element: any) => currencyPipe.transform(element.totalMonedaOrigen, '$'),
    },
    {
      columnDef: 'totalMonedaDestino',
      header: 'Monto destino',
      cell: (element: any) => currencyPipe.transform(element.totalMonedaDestino, '$'),
    },
    {
      columnDef: 'bancoDestino',
      header: 'Banco destino',
      cell: (element: any) => `${element.cuentaBeneficiario.banco.nombre}`,
    },
    {
      columnDef: 'estado',
      header: 'Estado',
      cell: (element: any) => `${element.estado.replaceAll('_',' ')}`,
    },
  ]

}


export const  columns = [
  {
    columnDef: 'id',
    header: 'ID',
    cell: (element: any) => `${element.id}`,
  },
  {
    columnDef: 'createdAt',
    header: 'Fecha creación',
    cell: (element: any) => `${element.createdAt ? moment(new Date(element.createdAt), 'America/Santiago').format('lll'): ''}`,
  },
  {
    columnDef: 'fechaTermino',
    header: 'Fecha Término',
    cell: (element: any) => `${element.fechaTermino ?  moment(new Date(element.fechaTermino), 'America/Santiago').format('lll')  : 'No aplica' }`,
  },
  {
    columnDef: 'fechaFondeoWallet',
    header: 'Fecha fondeo wallet',
    cell: (element: any) => `${element.fechaFondeoWallet  ?  moment(new Date(element.fechaFondeoWallet), 'America/Santiago').format('lll') : 'No aplica'}`,
  },

  {
    columnDef: 'userId',
    header: 'Nombre usuario',
    cell: (element: any) => `${element.usuario.nombre} ${element.usuario.apellidos}`,
  },
  {
    columnDef: 'cuentaBeneficiarioId',
    header: 'Beneficiario',
    cell: (element: any) => `${element.cuentaBeneficiario.nombre} ${element.cuentaBeneficiario.apellidos}`,
  },
  {
    columnDef: 'ruta',
    header: 'Ruta',
    cell: (element: any) => `${element.paisOrigen} - ${element.paisDestino}`,
  },
  {
    columnDef: 'totalMonedaOrigen',
    header: 'Monto origen',
    cell: (element: any) => `${element.totalMonedaOrigen}`,
  },
  {
    columnDef: 'totalMonedaDestino',
    header: 'Monto destino',
    cell: (element: any) => `${element.totalMonedaDestino}`,
  },
  {
    columnDef: 'bancoDestino',
    header: 'Banco destino',
    cell: (element: any) => `${element.cuentaBeneficiario.banco.nombre}`,
  },
  {
    columnDef: 'estado',
    header: 'Estado',
    cell: (element: any) => `${element.estado.replaceAll('_',' ')}`,
  },

];

export const  displayedColumns: string[] = [
  'seleccion',
  'id',
  'canal',
  'createdAt',
  'fechaFondeoWallet',
  'fechaTermino',
  'userId',
  'cuentaBeneficiarioId',
  'ruta',
  'bancoDestino',
  'totalMonedaOrigen',
  'totalMonedaDestino',
  'estado',
  'acciones',

];
