import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { interval, Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { UserRoles } from 'src/app/shared/enums/roles-enum';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';
import { RatesAcService } from '../../services/rates-ac-service.service';
import { TransaccionService } from 'src/app/dashboard/services/transaccion.service';
import { EstadosUsuario } from 'src/app/shared/enums/estados-usuario.enum';

@Component({
  selector: 'app-main-retail',
  templateUrl: './main-retail.component.html',
  styleUrls: ['./main-retail.component.sass']
})
export class MainRetailComponent implements OnDestroy, AfterViewInit, OnInit {
  subscription: Subscription = new Subscription();
  userRole: UserRoles = UserRoles.aguacate_retail_client;
  user: any;
  nombreUsuario: string = '';
  userEnum = UserRoles;
  estadoValidacion: number = -1;
  userEmail:string='';
  currentValues: any[]=[];
  rates: any[]=[];
  pendingCount:any;
  completedCount:any;
  yesterdayDate: Date= new Date();
  todayDate: Date = new Date();

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private spinner: SpinnerService,
    private ratesFiatService: RatesAcService,
    private transactionService: TransaccionService
    ) {
    let spinnerRef = this.spinner.start();
    this.subscription.add(
      this.store.select('auth').subscribe(({ user, userRole }: any) => {
        this.userRole = userRole;
        this.user = user;
        this.userEmail=user?.email;
          if (this.userRole === UserRoles.aguacate_retail_client) {
                  if (!this.isNotNullOrEmpty(user)) {
                    this.estadoValidacion = EstadosUsuario.incompleto;
                    return;
                  }
                  if (
                    this.isNotNullOrEmpty(user) &&
                    [0, 1, 2].includes(user.estadoCompliance.estadoID)
                  ) {
                    this.estadoValidacion = EstadosUsuario.pendienteValidacion;
                  }
                  if (
                    this.isNotNullOrEmpty(user) &&
                    user.estadoCompliance.estadoID === 3
                  ) {
                    this.estadoValidacion = EstadosUsuario.validado;
                  }
                }
      })
    );
    this.loadData();
    this.spinner.stop(spinnerRef);

  }
  ngOnInit() {
    this.loadData();

    this.subscription.add(
      interval(30000).subscribe(() => {
      this.loadData();
      })
    );
  }

  async loadData() {
    this.currentValues = await this.ratesFiatService.getRatesByProfile('client');
    this.yesterdayDate.setDate(this.todayDate.getDate() - 1);
    if(this.userRole===UserRoles.aguacate_retail_cashier){
      this.completedCount = await this.transactionService.getCompletedCount();
      this.pendingCount = await this.transactionService.getPendingCount();
    }
    this.rates = this.currentValues;
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isNotNullOrEmpty(object: Object) {
    return object && Object.keys(object).length > 0;
  }

  formatDate(date: any) {
    const months = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];

    return `${day} ${month}`;
  }

  redirect(route: string){
    this.router.navigate([route]);
  }


  enviarDinero(){
    Swal.fire({
      title: 'Enviar dinero',
      text:'Selecciona el tipo de envío',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonColor: '#1e5a3f',
      denyButtonColor: '#1e5a3f',
      confirmButtonText: 'Uno a uno',
      denyButtonText: `Múltiple`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['wallet/client/single']);
      } else if (result.isDenied) {
        this.router.navigate(['wallet/client/multiple']);
      }
    })
  }
}
