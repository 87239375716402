import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RetailHomeComponent } from "./components/retail-home/retail-home.component";
import { MainRetailComponent } from "./components/main-retail/main-retail.component";
import { RetailGuard } from "../shared/guards/retail.guard";
import { RatesAcComponent } from "./components/rates-ac/rates-ac.component";
import { CalculatorFiatComponent } from "./components/calculator-fiat/calculator-fiat.component";
import { WorkingAccountComponent } from "../dashboard/components/working-account/working-account.component";
import { PendingTransactionsAcComponent } from "./components/pending-transactions-ac/pending-transactions-ac.component";
import { CompletedTransactionsAcComponent } from "./components/completed-transactions-ac/completed-transactions-ac.component";
import { AccountRecipientComponent } from "../dashboard/components/account-recipient/account-recipient.component";
import { AdminTransactionsComponent } from "../dashboard/components/admin-transactions/admin-transactions.component";
import { TransactionsVesCompletedComponent } from "../dashboard/components/transactions-ves-completed/transactions-ves-completed.component";
import { AssignedTransactionsComponent } from "../dashboard/components/assigned-transactions/assigned-transactions.component";
import { BalanceCajeroComponent } from "../dashboard/components/balance-cajero/balance-cajero.component";
import { StatusCajeroComponent } from "../dashboard/components/status-cajero/status-cajero.component";
import { TransactionCashierComponent } from "../dashboard/components/transaction-cashier/transaction-cashier.component";
import { HoursOperationsComponent } from "../dashboard/components/hours-operations/hours-operations.component";
import { UsersBalanceSearchComponent } from "../dashboard/components/users-balance-search/users-balance-search.component";
import { UsersBalanceComponent } from "../dashboard/components/users-balance/users-balance.component";
import { GenerateCashClosingComponent } from "../dashboard/components/generate-rcash-closing/generate-cash-closing.component";
import { CreateUserComponent } from "./components/create-user/create-user.component";
import { ComplianceAcComponent } from "./components/compliance-ac/compliance-ac.component";
import { ComplianceUserAcComponent } from "./components/compliance-user-ac/compliance-user-ac.component";
import { ValidateUserComponent } from "../dashboard/components/validate-user/validate-user.component";


const routes: Routes = [
  {
    path: 'retail',
    component: RetailHomeComponent,
    canActivate: [RetailGuard],
    children: [
      { path: 'user-compliance', component: ValidateUserComponent},
      { path: 'home', component: MainRetailComponent },
      { path: 'rates', component: RatesAcComponent },
      { path: 'working-accounts', component: WorkingAccountComponent },
      { path: 'exchange', component: CalculatorFiatComponent },
      { path: 'exchange/:email', component: CalculatorFiatComponent },
      { path: 'transactionAc/pending', component: PendingTransactionsAcComponent },
      { path: 'transactionAc/completed', component: CompletedTransactionsAcComponent },
      { path: 'recipients', component: AccountRecipientComponent },
      { path: 'transactions', component: AdminTransactionsComponent },
      { path: 'completed-ves-transactions', component: TransactionsVesCompletedComponent },
      { path: 'pending-transactions', component: AssignedTransactionsComponent },
      { path: 'pending-transactions-binance', component: AssignedTransactionsComponent },
      { path: 'balance-cajero', component: BalanceCajeroComponent },
      { path: 'status-cajero', component: StatusCajeroComponent },
      { path: 'transaction-cashier', component: TransactionCashierComponent },
      { path: 'operation-hours', component: HoursOperationsComponent },
      { path: 'user-balance', component: UsersBalanceSearchComponent },
      { path: 'user-balance/:userId/:userEmail', component: UsersBalanceComponent },
      {path: 'cash-closing-report', component: GenerateCashClosingComponent },
      { path: 'new-user', component: CreateUserComponent },
      { path: 'compliance', component: ComplianceAcComponent },
      { path: 'compliance/:email', component: ComplianceUserAcComponent },
    ]
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes),

  ],
  exports: [RouterModule],
})
export class RetailRoutingModule { }

