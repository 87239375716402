<button  routerLink="/retail/compliance" class="mt-4 ml-4" mat-button>
    <mat-icon>arrow_back</mat-icon> Regresar</button>
  <mat-tab-group class="m-4">
    <mat-tab [label]="!isCompany ? 'Datos generales' : 'Datos representante legal'">
      <mat-card *ngIf="user" class="mt-4 w-full">
        <mat-card-content>
          <div class="grid grid-cols-3 gap-4">
            <div>
              <span class="font-semibold"> Nombre: <br /></span>
              {{ user.nombre }}
            </div>
            <div>
              <span class="font-semibold"> Apellidos: <br /></span>
              {{ user.apellidos }}
            </div>
            <div>
              <span class="font-semibold"> Documento: <br /></span>
              {{ user.tipoId }} {{ user.numeroId }}
            </div>
            <div *ngIf="!isCompany">
              <span class="font-semibold"> Fecha de nacimiento: <br /></span>
              {{ user.fechaNacimiento }}
            </div>
            <div>
              <span class="font-semibold"> Teléfono: <br /></span>
              {{ user.prefijoTelefono }} {{ user.telefono }}
            </div>
            <div *ngIf="!isCompany">
              <span class="font-semibold"> Nacionalidad: <br /></span>
              {{ user.nacionalidad }}
            </div>
  
            <div *ngIf="!isCompany">
              <span class="font-semibold"> País residencia: <br /></span>
              {{ user.paisResidencia }}
            </div>
            <div>
              <span class="font-semibold"> Correo: <br /></span>
              {{ user.email }}
            </div>
            <div *ngIf="!isCompany">
              <span class="font-semibold"> Profesion: <br /></span>
              {{ user.profesion }}
            </div>
          </div>
  
          <div class="mt-4 w-full flex flex-col items-center">
            <ng-container *ngIf="mostarFrontal">
              <h2 class="font-bold text-lg">Documento identidad frontal:</h2>
              <img class="sm:w-4/6 md:w-1/2" [src]="fotoIdFrontal" />
            </ng-container>
            <ng-container *ngIf="!mostarFrontal">
              <h2 class="font-bold text-lg">Documento identidad posterior:</h2>
              <img class="sm:w-4/6 md:w-1/2" [src]="fotoIdPosterior" />
            </ng-container>
            <div>
              <button
                class="mt-4"
                mat-raised-button
                color="primary"
                (click)="mostarFrontal = !mostarFrontal"
              >
                <mat-icon> rotate_right</mat-icon>
                Girar
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-tab>
    <mat-tab label="Datos empresa" *ngIf="isCompany">
      <mat-card *ngIf="user" class="mt-4 w-full">
        <mat-card-content>
          <div class="grid grid-cols-3 gap-4">
            <div>
              <span class="font-semibold"> Nombre o razón social: <br /></span>
              {{ user.razonSocial }}
            </div>
            <div>
              <span class="font-semibold"> Nombre comercial: <br /></span>
              {{ user.nombreComercial }}
            </div>
            <div>
              <span class="font-semibold"> Rut: <br /></span>
              {{ user.rutEmpresa }}
            </div>
            <div >
              <span class="font-semibold"> Domicilio fiscal: <br /></span>
              {{ user.domicilioFiscal }}
            </div>
  
          </div>
  
          <div class="mt-4 w-full grid grid-cols-3 gap-4 items-center">
            <div >
              <button  mat-raised-button
              color="primary" (click)="openTab(estatuto)">
                Estatuto
              </button>
  
            </div>
            <div >
              <button  mat-raised-button
              color="primary" (click)="openTab(documentoIdTributario)">
                Identificación Tributaria
              </button>
            </div>
  
          </div>
        </mat-card-content>
      </mat-card>
    </mat-tab>
    <mat-tab label="KYC">
      <mat-card *ngIf="user" class="mt-4">
  
  
       <mat-card-content>
  
          <div>
            <form
              class="grid grid-cols-2 gap-4"
              [formGroup]="formCompliance"
              *ngIf="formCompliance"
            >
              <div class="grid grid-cols-1">
                <div  >
                  <span class="font-semibold"> Estado Compliance: <br /></span>
                  {{user.estadoCompliance.descripcion}}
                </div>
                <div class="flex flex-row gap-4">
                  <button
                  class="mt-4"
                  mat-raised-button
                  color="primary"
                  (click)="aproveCompliance()" [disabled]="user.estadoComplianceID === 3"
                >
                  <mat-icon> check</mat-icon>
                  Aprobar
                </button>
                <button
                class="mt-4"
                mat-raised-button
                color="warn"
                (click)="rejectUser()"
                [disabled]="user.estadoComplianceID === 1"
              >
                <mat-icon> cancel</mat-icon>
                Rechazar
              </button>
              <button
              class="mt-4"
              mat-raised-button
              color="warn"  [disabled]="user.estadoComplianceID === 2"
              (click)="backlistUser()"
            >
              <mat-icon> error</mat-icon>
              Black list
            </button>
                </div>
              </div>
              <div>
                <p>
                  <mat-checkbox formControlName="politicamenteExpuesto"
                    >PEP</mat-checkbox
                  >
                </p>
                <p><mat-checkbox formControlName="esRTP">RTP</mat-checkbox></p>
                <p>
                  <mat-checkbox formControlName="esListaONU"
                    >LISTA ONU</mat-checkbox
                  >
                </p>
                <mat-hint class="text-sm" *ngIf="user.fuenteComplianceID === 2">
                  *Información traída de REGCHEQ
                </mat-hint>
                <div>
                  <button
                  class="mt-4"
                  mat-raised-button
                  color="primary"
                  (click)="guardarCompliance()"
  
                >
                  <mat-icon> check</mat-icon>
                  Guardar
                </button>
                </div>
  
              </div>
            </form>
  
  
          </div>
        </mat-card-content>
      </mat-card>
  
      <div></div>
    </mat-tab>
    <mat-tab label="Datos residenciales" *ngIf="!isCompany">
      <mat-card *ngIf="user" class="mt-4">
        <mat-card-content>
          <div class="grid grid-cols-1 gap-4">
            <div>
              <span class="font-semibold"> País residencia: <br /></span>
              {{ user.paisResidencia }}
            </div>
            <div>
              <span class="font-semibold"> Ciudad de residencia: <br /></span>
              {{ user.ciudadResidencia }}
            </div>
            <div>
              <span class="font-semibold"> Dirección: <br /></span>
              {{ user.direccion }}
            </div>
          </div>
        </mat-card-content>
      </mat-card>
  
      <div></div>
    </mat-tab>
  </mat-tab-group>
  