import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RetailRoutingModule } from './retail-routing.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RetailHomeComponent } from './components/retail-home/retail-home.component';
import { MainRetailComponent } from './components/main-retail/main-retail.component';
import { RatesAcComponent } from './components/rates-ac/rates-ac.component';
import { CalculatorFiatComponent } from './components/calculator-fiat/calculator-fiat.component';
import { BeneficiaryFiatComponent } from './components/calculator-fiat/components/beneficiary-fiat/beneficiary-fiat.component';
import { CalculatorFiatCalComponent } from './components/calculator-fiat/components/calculator-fiat-cal/calculator-fiat-cal.component';
import { FiatChangeComponent } from './components/calculator-fiat/components/fiat-change/fiat-change.component';
import { SummaryOrderFiatComponent } from './components/calculator-fiat/components/summary-order-fiat/summary-order-fiat.component';
import { PendingTransactionsAcComponent } from './components/pending-transactions-ac/pending-transactions-ac.component';
import { CompletedTransactionsAcComponent } from './components/completed-transactions-ac/completed-transactions-ac.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { ComplianceAcComponent } from './components/compliance-ac/compliance-ac.component';
import { ComplianceUserAcComponent } from './components/compliance-user-ac/compliance-user-ac.component';
import { ComplianceDialogComponent } from './components/compliance-user-ac/compliance-dialog/compliance-dialog.component';

@NgModule({
  declarations: [RetailHomeComponent, MainRetailComponent, RatesAcComponent, BeneficiaryFiatComponent, CalculatorFiatCalComponent, FiatChangeComponent,
    SummaryOrderFiatComponent, CalculatorFiatComponent, PendingTransactionsAcComponent, CompletedTransactionsAcComponent, CreateUserComponent, ComplianceAcComponent, ComplianceUserAcComponent, ComplianceDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RetailRoutingModule,
    MatSidenavModule,
  ],
  providers: [CurrencyPipe],
})
export class RetailModule { }
