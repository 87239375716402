<div class="h-screen flex flex-row justify-around items-center content-center gap-4	md:mx-4">
  <div class=" w-11/12 md:w-1/2 xl:w-2/5  h-auto  bg-aguacate-bg rounded-lg shadow  xs:p-0" *ngIf="!isConfirm">
    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
      <div class="container flex flex-col items-center">
        <img class="w-64" src="assets/img/aguacate-logo.png" alt="Aguacate cambios, profesionales en remesa" />
      </div>
      <form class="space-y-4 md:space-y-6" [formGroup]="signupForm" (ngSubmit)="onSubmit(signupForm)">
        <div>
          <label for="email" class="block mb-2 text-sm font-medium text-primary">Correo electrónico</label>
          <input type="email" name="email" id="email"  formControlName="email"
            class="bg-aguacate-green-50 border border-aguacate-green-300 text-primary-900 sm:text-sm rounded-lg focus:ring-aguacate-green-600 focus:border-aguacate-green-600 block w-full p-2.5"
            placeholder="name@company.com" required="true" />
            <div *ngIf="signupForm.get('email')?.invalid && (signupForm.get('email')?.dirty || signupForm.get('email')?.touched)">
              <span *ngIf="signupForm.get('email')?.hasError('required')" class="text-sm text-red-600"> Ingresa un email válido </span>
            </div>
            <div *ngIf="currentMail === signupForm.get('email')?.value &&  signUpError && signUpError.name === 'UsernameExistsException'">
              <span class="text-sm text-red-600">Este correo electrónico se encuentra registrado </span>
            </div>
        </div>
        <p class="font-bold"><mat-checkbox formControlName="isCompany">Cuenta Empresa</mat-checkbox></p>

        <div>
          <label for="password" class="block mb-2 text-sm font-medium text-gray-900" >Contraseña</label>
          <input type="password" name="password" id="password" placeholder="••••••••" formControlName="password"
            class="bg-aguacate-green-50 border border-aguacate-green-300 text-primary-900 sm:text-sm rounded-lg focus:ring-aguacate-green-600 focus:border-aguacate-green-600 block w-full p-2.5"
            required="" />
            <div *ngIf="signupForm.get('password')?.invalid && (signupForm.get('password')?.dirty || signupForm.get('password')?.touched)">
              <span *ngIf="signupForm.get('password')?.hasError('required')" class="text-sm text-red-600"> Ingresa la contraseña </span>
              <span *ngIf="signupForm.get('password')?.hasError('pattern')" class="text-sm text-red-600"> La contraseña debe contener:
                <ol>
                  <li>Mínimo 8 caracteres</li>
                  <li>Mínimo un numero</li>
                  <li>Mínimo una letra mayuscula y una minuscula</li>
                  <li>Mínimo un caracter especial ej: ^ $ * . [ ] {{'{ }' }} ( ) ? " ! "&#64;" # % & / \ , > < ' : ; | _ ~ ` = + - </li>
                </ol> </span>
            </div>

        </div>
        <div>
          <label for="passwordVerification" class="block mb-2 text-sm font-medium text-gray-900" >Confirmación de contraseña</label>
          <input type="password" name="passwordVerification" id="passwordVerification" placeholder="••••••••" formControlName="passwordVerification"
            class="bg-aguacate-green-50 border border-aguacate-green-300 text-primary-900 sm:text-sm rounded-lg focus:ring-aguacate-green-600 focus:border-aguacate-green-600 block w-full p-2.5"
            required="" />
            <div *ngIf="passwordMatchError">
              <span class="text-sm text-red-600"> Las contraseñas no coinciden </span>
            </div>

        </div>

        <div class="container flex flex-col items-center">
          <button type="submit" [disabled]="signupForm.invalid"
            class="w-1/2 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Registrarme
          </button>
          <p class="p-4 text-sm font-light text-primary-500 text-center">
            Al continuar, estás aceptando
            <a href="https://aguacate.s3-sa-east-1.amazonaws.com/POLITICAS+DE+PRIVACIDAD.pdf" rel="noreferrer"
              target="_blank" class="font-medium text-primary-600 hover:underline">
              nuestros Términos y Condiciones</a>
          </p>
        </div>
      </form>
      <div class="w-full container flex flex-row justify-around ">
        <button class="font-medium text-center text-primary-600 hover:underline" [routerLink]="['/auth/request-password-reset']">
          ¿Olvidaste tu contraseña?
        </button>
        <button class="font-medium text-center text-primary-600 hover:underline" [routerLink]="isRetail ? '/auth/ac' : '/auth/login'">
          ¿Ya tienes una cuenta? Inicia sesión
        </button>
      </div>
    </div>
  </div>


  <div class=" w-11/12 md:w-1/2 xl:w-2/5  h-auto  bg-white rounded-lg shadow  xs:p-0" *ngIf="isConfirm">
    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
      <div class="container flex flex-col items-center">
       <h1 class="font-medium"> Código de Verificación </h1>
       <p> Ingresa el código que te enviamos a tu correo electrónico </p>
      </div>

        <div class="space-y-4 md:space-y-6 " >
          <form  [formGroup]="confirmationForm" >
          <input matInput  type="text" id="code" name="code" formControlName="code" [class.error-input]="verificationError"
            class="bg-aguacate-green-50 border border-aguacate-green-300 text-primary-900 sm:text-sm rounded-lg focus:ring-aguacate-green-600 focus:border-aguacate-green-600 block w-full p-2.5"
            placeholder="XXXXXX" required="true" />
            <div *ngIf="verificationError && verificationError.name === 'CodeMismatchException'">
              <span class="text-sm text-red-600">Código de verificación incorrecto, vuelve a intentarlo </span>
            </div>
            <div *ngIf="verificationError && verificationError.name !== 'CodeMismatchException' && verificationError.message !== 'User cannot be confirmed. Current status is CONFIRMED'">
              <span class="text-sm text-red-600">Error al validar código </span>
            </div>

            <div *ngIf="verificationError && verificationError.message === 'User cannot be confirmed. Current status is CONFIRMED'">
              <span class="text-sm text-red-600">Este correo ya está verificado, <a class="underline"  routerLink="/auth/login">  por favor inicie sesión </a> </span>
            </div>

          </form>
          </div>

        <div class="container flex flex-col items-center">
          <button type="button" (click)="confirmSignUp()" [disabled]="confirmationForm.invalid"
            class="w-1/2 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Verificar código
          </button>

        </div>
    </div>
  </div>

</div>



