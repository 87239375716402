<div class="mt-4 w-full   flex flex-col justify-center items-center " >
  <h1 class="w-full text-2xl font-bold text-center mx-4"> Generar reporte de cierre de caja </h1>
  <div>

    <div class="text-center py-4 lg:px-4 mx-4 	w-full  flex flex-col items-center">
      <div class=" w-3/4 p-2 bg-aguacate-bg items-center text-indigo-100 leading-normal lg:rounded-full flex lg:inline-flex" role="alert">
        <span class="flex rounded-full bg-aguacate-green uppercase px-2 py-1 text-xs font-bold mr-3">!</span>
        <span class="font-light mr-2 text-left flex-auto text-black text-center px-5">  Esta opción elimina todos los registros del archivo de cierre de caja y genera un nuevo archivo con los registros de la fecha seleccionada
        </span>

      </div>
    </div>
  </div>
  <div class="w-full flex flex-row items-center justify-center gap-10 px-8">
    <mat-form-field >
      <mat-label>Selecciona una fecha</mat-label>
      <input matInput  [max]="maxDate" [matDatepicker]="picker" [(ngModel)]="selectedDate">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <button mat-flat-button color="primary" class="py-7 -mt-5 " (click)="closeCash()">Generar reporte</button>
  </div>


<hr>
<ng-container *ngIf="role !== 'aguacate_retail_cashier'">
  <h1 class="w-full text-2xl font-bold text-center mx-4 mt-8"> Generar reporte de línea de crédito </h1>
  <div>

    <div class="text-center py-4 lg:px-4 mx-4 	w-full  flex flex-col items-center">
      <div class=" w-3/4 p-2 bg-aguacate-bg items-center text-indigo-100 leading-normal lg:rounded-full flex lg:inline-flex" role="alert">
        <span class="flex rounded-full bg-aguacate-green uppercase px-2 py-1 text-xs font-bold mr-3">!</span>
        <span class="font-light mr-2 text-left flex-auto text-black text-center px-5">  Esta opción elimina todos los registros del archivo de cierre de caja y genera un nuevo archivo con los registros de la fecha seleccionada
        </span>

      </div>
    </div>
  </div>
  <div class="w-full flex flex-row items-center justify-center gap-10 px-8">


    <button mat-flat-button color="primary" class="py-7" (click)="creditLineReport()">Generar reporte</button>
  </div>
</ng-container>

</div>

