import * as moment from "moment-timezone";
import { Monedas } from "src/app/shared/enums/monedas";
import { obtenerMonedas } from "src/app/shared/utils/obtenerMonedas";
import { CurrencyPipe } from '@angular/common';


export function getColumns(currencyPipe: CurrencyPipe) {
  return [
    {
      columnDef: 'id',
      header: 'ID',
      cell: (element: any) => `${element.id}`,
    },
    {
      columnDef: 'createdAt',
      header: 'Fecha creación',
      cell: (element: any) => `${element.createdAt ? moment(new Date(element.createdAt), 'America/Santiago').format('lll'): ''}`,
    },
    {
      columnDef: 'fechaTermino',
      header: 'Fecha Término',
      cell: (element: any) => `${element.fechaTermino ?  moment(new Date(element.fechaTermino), 'America/Santiago').format('lll')  : 'No aplica' }`,
    },
    {
      columnDef: 'fechaFondeoWallet',
      header: 'Fecha fondeo wallet',
      cell: (element: any) => `${element.fechaFondeoWallet  ?  moment(new Date(element.fechaFondeoWallet), 'America/Santiago').format('lll') : 'No aplica'}`,
    },

    {
      columnDef: 'userId',
      header: 'Remitente',
      cell: (element: any) => `${element.usuario.nombre} ${element.usuario.apellidos}`,
    },
    {
      columnDef: 'beneficiarioDNI',
      header: 'DNI',
      cell: (element: any) => `${element.cuentaBeneficiario?.numeroID}`,
    },
    {
      columnDef: 'cuentaTrabajo',
      header: 'Cuenta trabajo',
      cell: (element: any) => `${element.cuentaTrabajo.nombre} `,
    },

    {
      columnDef: 'beneficiarioTipoCuenta',
      header: 'Tipo cuenta',
      cell: (element: any) => `${element.cuentaBeneficiario.tipoCuenta.nombre.toUpperCase()}`,
    },
    {
      columnDef: 'cuentaBeneficiarioId',
      header: 'Beneficiario',
      cell: (element: any) => `${element.cuentaBeneficiario?.nombre} ${element.cuentaBeneficiario?.apellidos}`,
    },
    {
      columnDef: 'ruta',
      header: 'Ruta',
      cell: (element: any) => `${element.paisOrigen} - ${element.paisDestino}`,
    },
    {
      columnDef: 'totalMonedaOrigen',
      header: 'Monto origen',
      cell: (element: any) => `${element.totalMonedaOrigen}`,
    },
    {
      columnDef: 'numeroCuenta',
      header: 'Cuenta',
      cell: (element: any) => `${element.cuentaBeneficiario.numeroCuenta}`,
    },
    // {
    //   columnDef: 'totalMonedaDestino',
    //   header: 'Monto destino',
    //   cell: (element: any) => `${currencyPipe.transform(element.totalMonedaDestino, '$')} ${element.monedaDestino}`
    // },
    {
      columnDef: 'bancoDestino',
      header: 'Banco destino',
      cell: (element: any) => `${element.cuentaBeneficiario.banco.nombre}`,
    },
  ]
}

export const  columns = [
  {
    columnDef: 'id',
    header: 'ID',
    cell: (element: any) => `${element.id}`,
  },
  {
    columnDef: 'createdAt',
    header: 'Fecha creación',
    cell: (element: any) => `${element.createdAt ? moment(new Date(element.createdAt), 'America/Santiago').format('lll'): ''}`,
  },
  {
    columnDef: 'fechaTermino',
    header: 'Fecha Término',
    cell: (element: any) => `${element.fechaTermino ?  moment(new Date(element.fechaTermino), 'America/Santiago').format('lll')  : 'No aplica' }`,
  },
  {
    columnDef: 'fechaFondeoWallet',
    header: 'Fecha fondeo wallet',
    cell: (element: any) => `${element.fechaFondeoWallet  ?  moment(new Date(element.fechaFondeoWallet), 'America/Santiago').format('lll') : 'No aplica'}`,
  },

  {
    columnDef: 'userId',
    header: 'Remitente',
    cell: (element: any) => `${element.usuario.nombre} ${element.usuario.apellidos}`,
  },
  {
    columnDef: 'beneficiarioDNI',
    header: 'DNI',
    cell: (element: any) => `${element.cuentaBeneficiario.numeroID}`,
  },
  {
    columnDef: 'cuentaTrabajo',
    header: 'Cuenta trabajo',
    cell: (element: any) => `${element.cuentaTrabajo.nombre} `,
  },

  {
    columnDef: 'beneficiarioTipoCuenta',
    header: 'Tipo cuenta',
    cell: (element: any) => `${element.cuentaBeneficiario.tipoCuenta.nombre.toUpperCase()}`,
  },
  {
    columnDef: 'cuentaBeneficiarioId',
    header: 'Beneficiario',
    cell: (element: any) => `${element.cuentaBeneficiario.nombre} ${element.cuentaBeneficiario.apellidos}`,
  },
  {
    columnDef: 'ruta',
    header: 'Ruta',
    cell: (element: any) => `${element.paisOrigen} - ${element.paisDestino}`,
  },
  {
    columnDef: 'totalMonedaOrigen',
    header: 'Monto origen',
    cell: (element: any) => `${element.totalMonedaOrigen}`,
  },
  {
    columnDef: 'numeroCuenta',
    header: 'Cuenta',
    cell: (element: any) => `${element.cuentaBeneficiario.numeroCuenta}`,
  },
  /* {
    columnDef: 'totalMonedaDestino',
    header: 'Monto destino',
    cell: (element: any) => currencyPipe.transform(element.totalMonedaDestino, '$), `${ element.monedaDestino }`,
  }, */
  {
    columnDef: 'bancoDestino',
    header: 'Banco destino',
    cell: (element: any) => `${element.cuentaBeneficiario.banco.nombre}`,
  },

];

export const  displayedColumns: string[] = [
  'seleccion',
  'id',
  'ruta',
  'userId',
  'cuentaBeneficiarioId',
  'cuentaTrabajo',
  'beneficiarioDNI',
  'bancoDestino',
  'beneficiarioTipoCuenta',
  'numeroCuenta',
  'totalMonedaDestino',

  'acciones',

];
