import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {NativeDateAdapter} from '@angular/material/core';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';
import { TransaccionService } from '../../services/transaccion.service';
import { CognitoService } from 'src/app/auth/services/cognito.service';
@Component({
  selector: 'app-generate-cash-closing',
  template: `<p>generate-rcash-closing works!</p>`,
  styleUrls: ['./generate-cash-closing.component.css'],
  templateUrl: './generate-cash-closing.component.html',
  providers: [NativeDateAdapter],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenerateCashClosingComponent {
  selectedDate: Date = new Date();
  maxDate: Date;
  role = '';
  constructor(private spinner: SpinnerService, private transactionService: TransaccionService, private cognito: CognitoService) {
    this.maxDate = new Date();
   this.getRole()
  }

  async getRole(){
   const user = await this.cognito.getUser();
    this.role = user.attributes['custom:user-group'];
  }

  closeCash() {
    const formatedDate = new Date(this.selectedDate).toISOString().split('T')[0];
    console.log(' Cierre de caja');
    console.log(formatedDate);
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esto sobreescribirá el cierre de caja anterior con la fecha indicada. ¿Deseas continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, cerrar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const ref = this.spinner.start();
        try {
          await this.transactionService.reportTransactions(formatedDate);
          Swal.fire('Transacciones reportadas', '', 'success');
        } catch (error) {
          if ((error as any)?.error?.message) {
            Swal.fire('Error', (error as any)?.error?.message, 'error');
          }else{
            Swal.fire('Error', 'Ocurrió un error inesperado', 'error');
          }

        }
        this.spinner.stop(ref);
      }
    })
  }

  creditLineReport() {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esto sobreescribirá el reporte de línea de crédito anterior. ¿Deseas continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, generar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const ref = this.spinner.start();
        try {
          await this.transactionService.reportCreditLine();
          Swal.fire('Reporte generado', '', 'success');
        } catch (error) {
          if ((error as any)?.error?.message) {
            Swal.fire('Error', (error as any)?.error?.message, 'error');
          }else{
            Swal.fire('Error', 'Ocurrió un error inesperado', 'error');
          }

        }
        this.spinner.stop(ref);
      }
    })
  }

 }
