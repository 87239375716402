import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './pages/home/home.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { SharedModule } from '../shared/shared.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { UserHomeComponent } from './components/user-home/user-home.component';
import { ValidateUserComponent } from './components/validate-user/validate-user.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { EditUserDataComponent } from './components/edit-user-data/edit-user-data.component';
import { ComplianceListComponent } from './components/compliance-list/compliance-list.component';
import { ComplianceUserComponent } from './components/compliance-user/compliance-user.component';
import { AccountRecipientComponent } from './components/account-recipient/account-recipient.component';
import { AccountComponent } from './components/account-recipient/components/account-list/account/account.component';
import { AccountListComponent } from './components/account-recipient/components/account-list/account-list.component';
import { MoneyOrderComponent } from './components/money-order/money-order.component';
import { CalculatorComponent } from './components/calculator/calculator.component';
import { SingleMoneyOrderComponent } from './components/money-order/single-money-order/single-money-order.component';
import { PaymentInstructionComponent } from './components/money-order/payment-instruction/payment-instruction.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PaymentValidationComponent } from './components/money-order/payment-validation/payment-validation.component';
import { MultipleMoneyOrderComponent } from './components/money-order/multiple-money-order/multiple-money-order.component';
import { RecipientSelectComponent } from './components/money-order/recipient-select/recipient-select.component';
import { OrdersListComponent } from './components/money-order/orders-list/orders-list.component';
import { CreatePayerComponent } from './components/payer/create-payer/create-payer.component';
import { PayerComponent } from './components/payer/list-payer/payer/payer.component';
import { ListPayerComponent } from './components/payer/list-payer/list-payer.component';
import { PagadorComplianceComponent } from './components/pagador-compliance/pagador-compliance.component';
import { AdminRateComponent } from './components/admin-rate/admin-rate.component';
import { BinanceRateComponent } from './components/admin-rate/binance-rate/binance-rate.component';
import { ManualRateComponent } from './components/admin-rate/manual-rate/manual-rate.component';
import { SetValuesComponent } from './components/admin-rate/manual-rate/set-values/set-values.component';
import { RatesComponent } from './components/rates/rates.component';
import { AdminTransactionsComponent } from './components/admin-transactions/admin-transactions.component';
import { DialogCambioComponent } from './components/dialog-cambio/dialog-cambio.component';
import { RateValueCardComponent } from './components/rate-value-card/rate-value-card.component';
import { AssignTransactionComponent } from './components/admin-transactions/assign-transaction/assign-transaction.component';
import { AssignedTransactionsComponent } from './components/assigned-transactions/assigned-transactions.component';
import { SendTransactionModalComponent } from './components/assigned-transactions/send-transaction-modal/send-transaction-modal.component';
import { RejectTransactionModalComponent } from './components/assigned-transactions/reject-transaction-modal/reject-transaction-modal.component';
import { EditTransactionModalComponent } from './components/assigned-transactions/edit-transaction-modal/edit-transaction-modal.component';
import { UnassignedTransactionsComponent } from './components/unassigned-transactions/unassigned-transactions.component';
import { ComplianceDialogComponent } from './components/compliance-user/compliance-dialog/compliance-dialog.component';
import { PaymentInstructionsMultipleComponent } from './components/money-order/payment-instructions-multiple/payment-instructions-multiple.component';
import { ChargeAccountComponent } from './components/charge-account/charge-account.component';
import { AccountDetailComponent } from './components/account-recipient/components/account-list/account-detail/account-detail.component';
import { DialogBeneficiariosComponent } from './components/account-recipient/components/account-list/dialog-beneficiarios/dialog-beneficiarios.component';
import { BalanceChangeComponent } from './components/calculator-cryto/components/balance-change/balance-change.component';
import { CalculatorCryptoComponent } from './components/calculator-cryto/components/calculator-crypto/calculator.component';
import { BeneficiaryComponent } from './components/calculator-cryto/components/beneficiary/beneficiary.component';
import { CalculatorCrytoComponent } from './components/calculator-cryto/calculator-cryto.component';
import { UsdtChangeComponent } from './components/calculator-cryto/components/usdt-change/usdt-change.component';
import { SummaryOrderComponent } from './components/calculator-cryto/components/summary-order/summary-order.component';
import { RecipientCriptoComponent } from './components/recipient-cripto/recipient-cripto.component';
import { RecipientCriptoListComponent } from './components/recipient-cripto/components/recipient-cripto-list/recipient-cripto-list.component';
import { RecipientCriptoCardComponent } from './components/recipient-cripto/components/recipient-cripto-list/recipient-cripto-card/recipient-cripto-card.component';
import { AddAccountCriptoComponent } from './components/recipient-cripto/components/add-account-cripto/add-account-cripto.component';
import { DialogBeneficiarioCriptoComponent } from './components/recipient-cripto/components/dialog-beneficiario-cripto/dialog-beneficiario-cripto.component';
import { CriptoHomeComponent } from './components/cripto-home/cripto-home.component';
import { CurrencyPipe } from '@angular/common';
import { CriptoRateComponent } from './components/cripto-rate/cripto-rate.component';
import { SetUsdtRateComponent } from './components/cripto-rate/set-usdt-rate/set-usdt-rate.component';
import { AssignedTransactionsCriptoComponent } from './components/assigned-transactions-cripto/assigned-transactions-cripto.component';
import { EditCriptoTransactionComponent } from './components/assigned-transactions-cripto/edit-cripto-transaction/edit-cripto-transaction.component';
import { SendTransactionaCriptoModalComponent } from './components/assigned-transactions-cripto/send-transactiona-cripto-modal/send-transactiona-cripto-modal.component';
import { RejectCompliancePagadorComponent } from './components/pagador-compliance/reject-compliance-pagador/reject-compliance-pagador.component';
import { HoursOperationsComponent } from './components/hours-operations/hours-operations.component';
import { RateUpdateTypeComponent } from './components/rate-update-type/rate-update-type.component';
import { ManualTransactionsComponent } from './components/manual-transactions/manual-transactions.component';
import { CreateFormDialogComponent } from './components/create-transaction-dialog/create-transaction-dialog.component';
import { ManualTransactionHistoryDialogComponent } from './components/manual-transaction-history-dialog/manual-transaction-history-dialog.component';
import { RemittanceComponent } from './components/remittance/remittance.component';
import { BillingRecordComponent } from './components/billing-record/billing-record.component';
import { ManualDepositComponent } from './components/manual-deposit/manual-deposit.component';
import { AccountsComponent } from './components/accounts/accounts.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog-component/confirmation-dialog-component.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AssignTrxDialogComponent } from './components/assign-trx-dialog/assign-trx-dialog.component';
import { GenerateCashClosingComponent } from './components/generate-rcash-closing/generate-cash-closing.component';
import { SetRatesParametersComponent } from './components/set-rates-parameters/set-rates-parameters.component';
import { AddWithdrawAccountsComponent } from './components/add-withdraw-accounts/add-withdraw-accounts.component';
import { WithdrawAccountsComponent } from './components/withdraw-accounts/withdraw-accounts.component';
import { WithdrawAccountListComponent } from './components/withdraw-accounts/withdraw-account-list/withdraw-account-list.component';
import { WithdrawAccountCardComponent } from './components/withdraw-accounts/withdraw-account-list/withdraw-account-card/withdraw-account-card.component';
import { WithdrawBalanceComponent } from './components/withdraw-balance/withdraw-balance.component';
import { CalculatorWithdrawComponent } from './components/withdraw-balance/components/calculator-withdraw/calculator-withdraw.component';
import { WithdrawBalanceViewComponent } from './components/withdraw-balance/components/withdraw-balance-view/withdraw-balance-view.component';
import { WithdrawAccountsStepComponent } from './components/withdraw-balance/components/withdraw-accounts-step/withdraw-accounts-step.component';
import { WithdrawSummaryOrderComponent } from './components/withdraw-balance/components/withdraw-summary-order/withdraw-summary-order.component';
import { WithdrawTrxListComponent } from './components/withdraw-trx-list/withdraw-trx-list.component';
import { RejectTransactionWithdrawModalComponent } from './components/withdraw-trx-list/reject-transaction-withdraw-modal/reject-transaction-withdraw-modal.component';
import { SendTransactionWithdrawModalComponent } from './components/withdraw-trx-list/send-transaction-withdraw-modal/send-transaction-withdraw-modal.component';
import { RecipientZelleComponent } from './components/recipient-zelle/recipient-zelle.component';
import { AddBeneficiaryZelleComponent } from './components/recipient-zelle/components/add-beneficiary-zelle/add-beneficiary-zelle.component';
import { DialogBeneficiaryZelleComponent } from './components/recipient-zelle/components/dialog-beneficiary-zelle/dialog-beneficiary-zelle.component';
import { RecipientZelleListComponent } from './components/recipient-zelle/components/recipient-zelle-list/recipient-zelle-list.component';
import { RecipientZelleCardComponent } from './components/recipient-zelle/components/recipient-zelle-list/recipient-zelle-card/recipient-zelle-card.component';
import { SetRatesParametersBolivaresComponent } from './components/set-rates-parameters-bolivares/set-rates-parameters-bolivares.component';
import { ZelleHomeComponent } from './components/zelle-home/zelle-home.component';
import { ZelleChangeComponent } from './components/calculator-zelle/components/zelle-change/zelle-change.component';
import { BeneficiaryZelleComponent } from './components/calculator-zelle/components/beneficiary-zelle/beneficiary-zelle.component';
import { CalculatorZelleComponent } from './components/calculator-zelle/calculator-zelle.component';
import { SummaryOrderZelleComponent } from './components/calculator-zelle/components/summary-order-zelle/summary-order-zelle.component';
import { CalculatorZelleCalComponent } from './components/calculator-zelle/components/calculator-zelle-cal/calculator-zelle-cal.component';
import { ZelleRateComponent } from './components/zelle-rate/zelle-rate.component';
import { SetZelleRateComponent } from './components/zelle-rate/set-zelle-rate/set-zelle-rate.component';
import { PendingTransactionsZelleComponent } from './components/pending-transactions-zelle/pending-transactions-zelle.component';
import { EditZelleTransactionComponent } from './components/pending-transactions-zelle/edit-zelle-transaction/edit-zelle-transaction.component';
import { SendTransactionsZelleModalComponent } from './components/pending-transactions-zelle/send-transactions-zelle-modal/send-transactions-zelle-modal.component';
import { CreditLineDialogComponent } from './components/credit-line-dialog/credit-line-dialog.component';
import { TransactionsDashboardComponent } from './components/transactions-dashboard/transactions-dashboard.component';
import { WithdrawTrxSentComponent } from './components/withdraw-trx-sent/withdraw-trx-sent.component';
import { TransactionsVesCompletedComponent } from './components/transactions-ves-completed/transactions-ves-completed.component';
import { UserSegmentationComponent } from './components/user-segmentation/user-segmentation.component';
import { DialogSegmentationComponent } from './components/user-segmentation/dialog-segmentation/dialog-segmentation.component';
import { DialogAssignPointsComponent } from './components/user-segmentation/dialog-assign-points/dialog-assign-points.component';
import { SetupMfaComponent } from './components/user-profile/setup-mfa/setup-mfa.component';



@NgModule({
  declarations: [
    HomeComponent,
    NavbarComponent,
    UserHomeComponent,
    ValidateUserComponent,
    UserProfileComponent,
    EditUserDataComponent,
    ComplianceListComponent,
    ComplianceUserComponent,
    AccountRecipientComponent,
    AccountComponent,
    AccountListComponent,
    MoneyOrderComponent,
    CalculatorComponent,
    SingleMoneyOrderComponent,
    PaymentInstructionComponent,
    PaymentValidationComponent,
    MultipleMoneyOrderComponent,
    RecipientSelectComponent,
    OrdersListComponent,
    CreatePayerComponent,
    PayerComponent,
    ListPayerComponent,
    PagadorComplianceComponent,
    AdminRateComponent,
    BinanceRateComponent,
    ManualRateComponent,
    SetValuesComponent,
    RatesComponent,
    AdminTransactionsComponent,
    DialogCambioComponent,
    RateValueCardComponent,
    AssignTransactionComponent,
    AssignedTransactionsComponent,
    SendTransactionModalComponent,
    RejectTransactionModalComponent,
    EditTransactionModalComponent,
    UnassignedTransactionsComponent,
    ComplianceDialogComponent,
    PaymentInstructionsMultipleComponent,
    ChargeAccountComponent,
    AccountDetailComponent,
    DialogBeneficiariosComponent,
    UsdtChangeComponent,
    BalanceChangeComponent,
    CalculatorCryptoComponent,
    BeneficiaryComponent,
    CalculatorCrytoComponent,
    SummaryOrderComponent,
    RecipientCriptoComponent,
    RecipientCriptoListComponent,
    RecipientCriptoCardComponent,
    AddAccountCriptoComponent,
    DialogBeneficiarioCriptoComponent,
    CriptoHomeComponent,
    CriptoRateComponent,
    SetUsdtRateComponent,
    AssignedTransactionsCriptoComponent,
    EditCriptoTransactionComponent,
    SendTransactionaCriptoModalComponent,
    RejectCompliancePagadorComponent,
    HoursOperationsComponent,
    RateUpdateTypeComponent,
    ManualTransactionsComponent,
    CreateFormDialogComponent,
    ManualTransactionHistoryDialogComponent,
    RemittanceComponent,
    BillingRecordComponent,
    ManualDepositComponent,
    AccountsComponent,
    ConfirmationDialogComponent,
    AssignTrxDialogComponent,
    GenerateCashClosingComponent,
    SetRatesParametersComponent,
    AddWithdrawAccountsComponent,
    WithdrawAccountsComponent,
    WithdrawAccountListComponent,
    WithdrawAccountCardComponent,
    WithdrawBalanceComponent,
    CalculatorWithdrawComponent,
    WithdrawBalanceViewComponent,
    WithdrawAccountsStepComponent,
    WithdrawSummaryOrderComponent,
    WithdrawTrxListComponent,
    RejectTransactionWithdrawModalComponent,
    SendTransactionWithdrawModalComponent,
    RecipientZelleComponent,
    AddBeneficiaryZelleComponent,
    DialogBeneficiaryZelleComponent,
    RecipientZelleListComponent,
    RecipientZelleCardComponent,
    SetRatesParametersBolivaresComponent,
    ZelleHomeComponent,
    ZelleChangeComponent,
    BeneficiaryZelleComponent,
    CalculatorZelleComponent,
    SummaryOrderZelleComponent,
    CalculatorZelleCalComponent,
    ZelleRateComponent,
    SetZelleRateComponent,
    PendingTransactionsZelleComponent,
    EditZelleTransactionComponent,
    SendTransactionsZelleModalComponent,
    CreditLineDialogComponent,
    TransactionsDashboardComponent,
    WithdrawTrxSentComponent,
    TransactionsVesCompletedComponent,
    UserSegmentationComponent,
    DialogSegmentationComponent,
    DialogAssignPointsComponent,
    SetupMfaComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardRoutingModule,
    SharedModule,
    ClipboardModule,
    MatDialogModule,

  ],
  providers: [
    CurrencyPipe,
  ],
  exports: [
    CreateFormDialogComponent,
    ManualTransactionHistoryDialogComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ]
})
export class DashboardModule { }
