import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { CustomValidators } from 'src/app/shared/utils/custom.validator';
import { CognitoError } from '../../interfaces/cognitoError.interface';
import { CognitoService, IUser } from '../../services/cognito.service';
import { bool } from 'aws-sdk/clients/signer';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/dashboard/services/user.service';
import { Subscription } from 'rxjs';
import { UserRoles } from 'src/app/shared/enums/roles-enum';


interface formDataInterface {
  "name": string;
  "family_name": string;
  "custom:university": string;
  "email": string;
  "phone_number": string;
  [key: string]: string;
};

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.sass']
})
export class SignUpComponent implements OnDestroy{
  subscription = new Subscription();
  isRetail = false;
  loading: boolean;
  isConfirm: boolean=false;
  confirmationCode: string= '';
  signupForm: FormGroup;
  currentMail: string = '';
  signUpError: CognitoError | undefined;
  verificationError: CognitoError | undefined;
  confirmationForm: FormGroup;
  resendSignUp: boolean = false;
  private readonly COGNITO_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\"!@#%&\/\\,><\':;|_~`=+\-])[a-zA-Z0-9\^$*.\[\]{}\(\)?\"!@#%&\/\\,><\':;|_~`=+\-]{8,98}$/;
  constructor(private router: Router, private userService: UserService,
              private cognitoService: CognitoService, private fb: FormBuilder, private spinner: SpinnerService) {
    this.signupForm = this.fb.group({
      email: new FormControl('', [Validators.email]),
      password: new FormControl('', [Validators.required, Validators.pattern(this.COGNITO_PATTERN)],),
      passwordVerification: new FormControl('', [Validators.required]),
      isCompany: new FormControl(false, []),
    },{validators: [CustomValidators.MatchValidator('password', 'passwordVerification')]});
    this.router.events.subscribe((val) => {
      if (this.router.url.includes('retail-signup')) {
      this.isRetail = true;
      console.log('retail');
      }
    });
    this.confirmationForm = this.fb.group({
      code: new FormControl('', [Validators.required])

    });
    this.loading = false;
    this.isConfirm = false;
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get passwordMatchError() {
    var mismatch =  (
      this.signupForm.getError('mismatch') &&
      (this.signupForm.get('passwordVerification')?.touched || this.signupForm.get('passwordVerification')?.dirty)
    );
    return mismatch;
  }

  public signUp(): void {
    this.resendSignUp = true;
    this.currentMail = '';
    this.signUpError = undefined;
    let spinnerRef = this.spinner.start();
    this.cognitoService.signUp(this.signupForm.get('email')?.value, (this.signupForm.get('password')?.value ), +this.signupForm.get('isCompany')?.value, this.isRetail? UserRoles.aguacate_retail_client : UserRoles.aguacate_client)
    .then(() => {
      this.spinner.stop(spinnerRef);
      this.isConfirm = true;
    }).catch((err: any) => {
      console.log(err);
      this.currentMail = this.signupForm.get('email')?.value;
      this.signUpError = err;
      if(this.signUpError?.name === 'UsernameExistsException'){
        let spinnerRef = this.spinner.start();
        this.cognitoService.resendConfirmationCode(this.currentMail).then(()=>{
          this.resendSignUp= true;
          this.spinner.stop(spinnerRef);
          this.isConfirm = true;
        }).catch((res)=>{
          this.spinner.stop(spinnerRef);
        })
      }
      this.spinner.stop(spinnerRef);
    });
  }

  onSubmit(formGroup: FormGroup){
    this.signUp();
  }

  public async confirmSignUp(): Promise<void> {

    this.verificationError = undefined;
    let spinnerRef = this.spinner.start();
    this.cognitoService.confirmSignUp(this.signupForm.get('email')?.value, this.confirmationForm.get('code')?.value )
    .then(async () => {
      this.spinner.stop(spinnerRef);
      await Swal.fire({
        icon: 'success',
        title: 'Correo electrónico verificado',
        text: 'Su correo fue verificado con éxito',
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Entendido',
        confirmButtonColor: '#1e5a3f',

      });
      if(this.resendSignUp){
        if(this.isRetail){
          this.router.navigate(['/ac/auth']);
        }else{
          this.router.navigate(['/auth/login']);
        }

      }else{
        this.cognitoService.signIn(this.signupForm.get('email')?.value, this.signupForm.get('password')?.value).then(()=>{
          if(this.isRetail){
            this.router.navigate(['/retail/home']);
          }else{
            this.router.navigate(['/wallet']);
          }

        })
      }

      //this.router.navigate(['/auth/login']);
    }).catch((err) => {
      this.verificationError= err;
      this.confirmationForm.reset();
      this.confirmationForm.get('code')?.markAsDirty();
      this.spinner.stop(spinnerRef);
      this.loading = false;
    });
  }
}
