<div class="m-4 flex flex-col justify-center">
  <h1 class="m-4 text-xl font-bold text-center">Movimientos de usuarios</h1>

  <div class="flex flex-row gap-5 align-middle mb-4 justify-center flex-wrap" style="max-width: 800px; margin: 0 auto;">

      <mat-form-field class="w-[250px] sm:w-[220px]">
        <mat-label>Tipo de búsqueda</mat-label>
        <mat-select required [(ngModel)]="selectedFilter">
          <mat-option>Seleccione un filtro</mat-option>
          <mat-option *ngFor="let filter of filters" [value]="filter">
            {{ filterDescriptions[filter]}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field class="w-[250px] sm:w-[220px]">
      <mat-label>Valor de búsqueda</mat-label>
      <input matInput type="text" [(ngModel)]="searchValue" />

      <button *ngIf="searchValue"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="searchValue = ''"
      >
        <mat-icon>close</mat-icon>
      </button>

    </mat-form-field>
      <button mat-raised-button color="primary" (click)="search()" class="mt-[1px] h-[56px] button-search">
        Buscar
      <mat-icon> search </mat-icon>
    </button>

  </div>
  <div class="my-4 flex overflow-x-auto max-w-[100%]" >
    <div class="table-container" style="max-width: 800px; margin: 0 auto;">
      <table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" class="w-full" *ngIf="dataSource" matSort>
        <ng-container
          *ngFor="let column of columns"
          [matColumnDef]="column.columnDef"
        >
          <th mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          style="background-color: #CEE9C2; text-align:left"
          [ngStyle]="column.headerStyle"
          >
          {{column.header}}
        </th>
          <td mat-cell
          *matCellDef="let row"
          style="text-align:left"
          [ngStyle]="column.cellStyle"
          >
          {{column.cell(row)}}
        </td>
        </ng-container>
        <ng-container matColumnDef="acciones" stickyEnd>
          <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="background-color: #CEE9C2; text-align: center">Ver movimientos</th>
          <td mat-cell *matCellDef="let element" style="text-align: center">
            <button mat-icon-button color="primary" aria-label="ver" [routerLink]="[path, element.usuarioId, element.email]">
              <mat-icon>visibility</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="acciones-cashier" stickyEnd>
          <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="background-color: #CEE9C2; text-align: center">Acciones</th>
            <td mat-cell *matCellDef="let element" style="text-align: center; display: flex; justify-content: center;">
            <button mat-icon-button color="primary" aria-label="ver" [routerLink]="[path, element.usuarioId, element.email]">
              <mat-icon>person_add</mat-icon>
            </button>
            <button mat-icon-button color="primary" aria-label="ver" [routerLink]="['/retail/exchange', element.email]">
              <mat-icon>currency_exchange</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
