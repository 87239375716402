import { Component, NgModule } from '@angular/core';
import { TransaccionService } from '../../services/transaccion.service';
import { Transaccion } from '../../interfaces/transaccion.interface';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { getColumns } from './table-transactions-columns';
import { GetTransactionsType } from 'src/app/shared/enums/get-transactions-type';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { SendTransactionModalComponent } from './send-transaction-modal/send-transaction-modal.component';
import { EditTransactionModalComponent } from './edit-transaction-modal/edit-transaction-modal.component';
import { RejectTransactionModalComponent } from './reject-transaction-modal/reject-transaction-modal.component';
import { ActionsService } from 'src/app/shared/services/actions.service';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import Swal from 'sweetalert2';

import { CurrencyPipe } from '@angular/common';
import { BinanceService } from '../../services/binance.service';


@Component({
  selector: 'app-assigned-transactions',
  templateUrl: './assigned-transactions.component.html',
  styleUrls: ['./assigned-transactions.component.sass']
})
export class AssignedTransactionsComponent {

  transacciones: Transaccion[] = [];
  seleccionados: number = 0;
  dataSource: MatTableDataSource<any> | undefined;
  subscription: Subscription = new Subscription();
  columns = getColumns(this.currencyPipe);
  displayedColumns: string[] = [
    'seleccion',
    'id',
    'ruta',
    'userId',
    'cuentaBeneficiarioId',
    'cuentaTrabajo',
    'beneficiarioDNI',
    'bancoDestino',
    'beneficiarioTipoCuenta',
    'numeroCuenta',
    'totalMonedaDestino',

  ];
  emailOperador = '';
  cambios: any = {};
  isBinance: boolean = false;
  currentRates: any;
  currentValues: any;

  constructor(
    private transactionService: TransaccionService,
    private dialog: MatDialog,
    private auth: CognitoService,
    private actionService: ActionsService,
    private router: Router,
    private clipboard: Clipboard,
    private currencyPipe: CurrencyPipe,
    private binanceService: BinanceService
  ) {
    this.isBinance = this.router.url.includes('pending-transactions-binance');
    if (this.isBinance) {
      this.displayedColumns.push('precioVenta');
      this.displayedColumns.push('pago');
    }
    this.displayedColumns.push('acciones');
    this.getCurrentRates();
    this.getCurrentValues();

  }

  refreshRates() {
    this.getCurrentRates();
  }

  currentRate(bank: string) {
    let currentRate = 0
    switch (bank) {
      case 'PROVINCIAL':
        currentRate = this.currentRates.valoresVES.valorProvincial;
        break;
      case 'BANESCO':
        currentRate = this.currentRates.valoresVES.valorBanesco;
        break;
      case 'MERCANTIL':
        currentRate = this.currentRates.valoresVES.valorMercantil;
        break;
      case 'VENEZUELA':
        currentRate = this.currentRates.valoresVES.valorVenezuela;
        break;
      default:
        console.log('cuenta beneficiario no tiene un banco con tasa manual');
        break;
    }
    return currentRate;
  }

  async ngOnInit() {
    await this.loadTransactions();
    this.subscription.add(this.actionService.subjectAssignedTransaction.subscribe(async res => {
      if (res) {
        this.loadTransactions();
      }
    }));
  }

  async loadTransactions() {
    let authData = await this.auth.getUser();
    this.emailOperador = authData.attributes.email;
    const bankType = this.isBinance ? 'BINANCE' : undefined;
    this.transacciones = await this.transactionService.getAllTransactions(GetTransactionsType.ASSIGNED, bankType);
    this.dataSource = new MatTableDataSource(this.transacciones);
    this.cambios = {};
  }

  async getCurrentRates() {
    this.currentRates = await this.binanceService.getCurrentRates();
  }
  async getCurrentValues() {
    this.currentValues = await this.binanceService.getConversionData();
  }

  isObjectEmpty = (objectName: any) => {
    return Object.keys(objectName).length === 0
  }
  copy(element: Transaccion) {

    const text =
      `${element.cuentaBeneficiario.nombre} ${element.cuentaBeneficiario.apellidos}\n${element.cuentaBeneficiario.numeroID}\n${element.cuentaBeneficiario.numeroCuenta}\n${element.cuentaBeneficiario.banco?.nombre}`
    this.clipboard.copy(text);
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    })

    Toast.fire({
      icon: 'success',
      title: 'Transferencia copiada al portapapeles'
    })


  }
  async setPurchaseValue(transactionId: number, id: number) {
    if (!transactionId || !id) return;

    console.log(transactionId, id);

    const value = (<HTMLInputElement>document.getElementById(`valorCompra${id}`)).value.replaceAll(',', '');
    Swal.fire({
      title: 'Guardando precio de compra ',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    await this.transactionService.setPurchaseValue({
      transactionId, value
    });
    Swal.close();

  }
  async guardarSeleccion() {
    const cambioFormateados = Object.keys(this.cambios).map((key) => {
      return {
        id: +key,
        checked: this.cambios[key]
      };
    });
    const body = {
      idTransaction: cambioFormateados,
      operatorEmail: this.emailOperador
    };
    await this.transactionService.changeAssignationStatus(body);
    await this.loadTransactions();
  }
  change(event: any, id: number) {

    this.cambios[id] = event.checked;
  }

  checkEmailOperador(email: string) {
    if (!email) return false;
    if (this.emailOperador !== email) return true;
    return false;
  }

  disableButton(email: string) {
    if (this.emailOperador !== email) return true;
    return false;
  }

  limpiarSeleccion() {
    const data =
      this.dataSource?.data.map((value) => {
        return { ...value, seleccionado: false };
      }) || [];

    if (this.dataSource && this.dataSource.data) {
      this.dataSource.data = data;
    }
    // this.change();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.dataSource) {
      this.dataSource.filter = filterValue.trim().toUpperCase();
    }
  }

  public doFilter = (event: any) => {
    const element = event.currentTarget as HTMLInputElement;
    const value = element.value;
    if (this.dataSource)
      this.dataSource.filter = value.trim().toLocaleLowerCase();
  };
  //TPDP

  returnSelectedTransactions(row: Transaccion) {
    const selected =
      this.dataSource?.data?.filter((x) => x.seleccionado && x.id !== row.id) ||
      [];
    selected.push(row);
    return selected;
  }

  formatCurrencyForCopy(value: number): string {
    const valueString = value.toString();
    return valueString.replace(/\./g, ',');
  }

  openSendTransactionModal(row: any) {

    console.log(row);
    const dialogRef = this.dialog.open(SendTransactionModalComponent, {
      data: row,
      maxHeight: '70%',
      maxWidth: '826px',
      minWidth: '800px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === true) {
        this.loadTransactions();
      }
      // if(result == true && this.usuarioID){
      //   this.listaCuentas = await this.accountRecipientService.getByUser(this.usuarioID);
      // }
    });
  }

  openEditTransactionModal(row: any) {
    const dialogRef = this.dialog.open(EditTransactionModalComponent, {
      data: row,
      maxHeight: '70%',
      maxWidth: '826px',
      minWidth: '800px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === true) {
        this.loadTransactions();
      }
      // if(result == true && this.usuarioID){
      //   this.listaCuentas = await this.accountRecipientService.getByUser(this.usuarioID);
      // }
    });
  }
  openRejectTransactionModal(row: any) {
    const dialogRef = this.dialog.open(RejectTransactionModalComponent, {
      data: row,
      maxHeight: '70%',
      maxWidth: '826px',
      minWidth: '800px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === true) {
        this.loadTransactions();
      }
      // if(result == true && this.usuarioID){
      //   this.listaCuentas = await this.accountRecipientService.getByUser(this.usuarioID);
      // }
    });
  }
}
