import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { AuthComponent } from './pages/auth/auth.component';
import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CarouselWalletComponent } from './components/carousel-wallet/carousel-wallet.component';
import { MailValidationComponent } from './components/mail-validation/mail-validation.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { RequestPasswordResetComponent } from './components/request-password-reset/request-password-reset.component';
import { LogoutCountdownComponent } from './components/logout-countdown/logout-countdown.component';
import { AfterLoginComponent } from './components/after-login/after-login.component';
import { SignInAcComponent } from './components/sign-in-ac/sign-in-ac.component';

@NgModule({
  declarations: [SignInComponent, SignUpComponent, AuthComponent, CarouselWalletComponent, MailValidationComponent, PasswordResetComponent, RequestPasswordResetComponent, LogoutCountdownComponent, AfterLoginComponent,
    SignInAcComponent],
  imports: [CommonModule, AuthRoutingModule, SharedModule]
})
export class AuthModule {}
